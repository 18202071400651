.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.648);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  z-index: 1;
  position: absolute;
  background-color: white;
}

.header > h2 {
  margin: 0;
}