.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
}

.login-box-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-box-input > .input {
    margin-bottom: 10px;
}

.login-box {
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 300px;
    justify-content: space-between;
}