hr {
  width: 100%;
  border: none;
  border-bottom: solid 1px grey;
}

.quick-info {
  padding: 1em;
  padding-top: 70px;
  color: white;
  background: rgb(8,0,135);
  background: linear-gradient(27deg, rgba(8,0,135,1) 0%, rgba(9,9,121,1) 42%, rgba(0,134,161,1) 100%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.quick-info > h3, p {
  margin: 0;
}

.quick-info-buttons {
  display: flex;
  justify-content: space-around;

}

.quick-info-button:hover {
  background:rgb(7, 0, 103);
}